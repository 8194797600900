import * as React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import DefaultLayout from "../layouts/Default";
import RoundImage from "../components/RoundImage";
import RoundContentIndex from "../components/RoundContentIndex";
import { breakpoints, colors } from "../styles/__variables";

const styles = {
  background: "url(assets/images/background/photocase_937820.jpg)",
  navColorDesktop: colors.white,
  navColor: colors.green.base,
  // height: "50vh",
};

const IndexFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;

  @media screen and (min-width: ${`${breakpoints.xl}px`}) {
    flex-direction: row;
  }
`;
// hier nur String übergeben
const IndexPage = () => {
  return (
    <DefaultLayout styles={styles}>
      <Helmet
        title="Rundum HR"
        link={[
          {
            rel: "shortcut icon",
            type: "image/png",
            href: `./assets/favicon/android-chrome-192x192.png`,
          },
        ]}
      >
        <meta
          name="description"
          content="Ich unterstütze Sie bei der Konzeption und Realisierung Ihrer HR-Projekte, berate Sie zu HR-Fragen oder coache Sie als Führungsperson."
        />
      </Helmet>
      <IndexFlex>
        <RoundContentIndex />
        <RoundImage
          portrait={false}
          source="/assets/images/andrea_rechsteiner_DHZ6161.jpg"
        />
      </IndexFlex>
    </DefaultLayout>
  );
};

export default IndexPage;
