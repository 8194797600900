import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { spacers, breakpoints, colors } from "../styles/__variables";
import Logo from "./Logo";
import Title from "./Title";
import Paragraph from "./Paragraph";

const RoundContentIndexDiv = styled.div`
  background: ${colors.green.base};
  margin: 0 ${`-${spacers.mobile}px`} ${`-${spacers.mobile}px`};
  padding: ${`${spacers.desktop}px`} ${`${spacers.mobile}px`};
  order: 2;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p,
  h1 {
    margin: ${`${spacers.desktop}px`} auto;
    text-align: center;
  }

  > div:first-of-type {
    overflow: scroll;
  }

  @media screen and (min-width: ${`${breakpoints.md}px`}) {
    padding: ${`${spacers.desktop}px`} ${`${spacers.tablet}px`};
    margin: 0 ${`-${spacers.tablet}px`} ${`-${spacers.tablet}px`};
  }

  @media screen and (min-width: ${`${breakpoints.xl}px`}) {
    position: absolute;
    order: 1;
    padding: 100px;
    border-radius: 10000px;
    height: 1150px;
    width: 1150px;
    margin: 0;
    margin-left: -30vh;
    top: 90px;
    bottom: -90px;

    p,
    h1,
    img {
      display: block;
      margin: 0 0 ${`${spacers.desktop}px`} 0;
      text-align: left;
    }
    > div > a {
      display: block;
      margin-bottom: ${`${spacers.desktop}px`};
    }
    > div {
      display: block;
      margin: 0 0 ${`${spacers.desktop * 2}px`} 0;
      text-align: left;
    }

    > div {
      position: absolute;
      top: 64px;
      bottom: 64px;
      left: 30vh;
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      width: 760px;
      height: calc(100vh - 128px);

      > div {
        position: relative;
      }
    }

    @media screen and (max-height: 900px) {
      top: -200px;
      bottom: 0px;

      > div {
        justify-content: flex-start;
        bottom: ${`${spacers.desktop}px`};
        top: ${`${200 + spacers.desktop}px`};
      }
    }
  }

  @media screen and (min-width: ${`${breakpoints.xxl}px`}) {
    height: 1350px;
    width: 1350px;
    > div {
      top: 150px;
      width: 800px;
      height: 900px;

      > a {
        margin-bottom: 128px;
      }

      &:first-of-type {
        img {
          max-height: 298px;
        }
      }
    }
  }
`;

const RoundContentIndex: React.FC = () => {
  return (
    <RoundContentIndexDiv>
      <div>
        <Link to="angebot">
          <Logo />
        </Link>

        <Title topspaced color="white">
          Hier dreht sich alles
          <br /> rund ums HR
        </Title>
        <Paragraph color="white">
          Ich unterstütze Sie bei der Konzeption und Realisierung Ihrer
          HR-Projekte, berate Sie zu HR-Fragen oder coache Sie als
          Führungsperson.
        </Paragraph>
      </div>
    </RoundContentIndexDiv>
  );
};

export default RoundContentIndex;
